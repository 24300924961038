import React from "react";
import '../AbrirFin.css'; // Import the CSS file for the sliding effect


const SlidingText = () => {
  return (
    <div className="scrolling-container">
        <div className="scrolling-text">
          <p>Abrimos el día 31.12. desde las 9.00 hasta las 18.00</p>
        </div>
        </div>
  );
};

export default SlidingText;
