import React from "react";
import "./Hero.css";
import { Link } from "react-router-dom";
import { TranslationContext } from "../TranslationContext";
import About from "../components/pages/About";
import GalleryPreview from "../components/pages/GalleryPreview";
import Service from "../components/pages/Service";
import ReservarMain from "../components/pages/ReservarMain";
import SlidingText from "./pages/AbrirFin";

function Hero() {
  const { translation } = React.useContext(TranslationContext);

  const scrollToAbout = () => {
    const element = document.getElementById("about-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Element with ID 'about-section' not found");
    }
  };

  return (
    <div className="main-page">
      <div className="hero">
        <SlidingText/>
        <div className="hero-content">
          <h1>
            {translation.welcome}
            <br />
            <span className="highlight">Parrilla Las Nieves</span>
          </h1>
          <Link to="/Reservacion" className="btn">
            {translation.reserve}
          </Link>
        </div>
        <div className="center-con" onClick={scrollToAbout}>
          <div className="round">
            <span className="arrow"></span>
            <span className="arrow"></span>
            <span className="arrow"></span>
            <span className="arrow"></span>
          </div>
        </div>
      </div>
      <div id="about-section">
        <About />
      </div>
      <GalleryPreview />
      <Service />
      <ReservarMain />
    </div>
  );
}

export default Hero;
